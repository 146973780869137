import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { Menu } from '@headlessui/react'

const ServicesDropdown = () => {
    return (
        <Menu as='div' className='relative inline-block text-left'>
            <Menu.Button>Serviços de Terapia <FontAwesomeIcon icon={faCaretDown} /></Menu.Button>
            <Menu.Items className='mb-2 sm:absolute left-0 w-56 mt-2 origin-top-left bg-white sm:divide-y sm:divide-gray-100 rounded-md sm:shadow-lg sm:ring-1 sm:ring-black sm:ring-opacity-5 focus:outline-none'>
                <Menu.Item>
                    {({ active }) => <Link to='/psicoterapia-individual' className='flex rounded-md w-full p-2 text-sm'>Psicoterapia Individual</Link>}
                </Menu.Item>
                <Menu.Item>
                    {({ active }) => <Link to='/terapia-de-casal' className='flex rounded-md w-full p-2 text-sm'>Terapia de Casal</Link>}
                </Menu.Item>
                <Menu.Item>
                    {({ active }) => <Link to='/terapia-de-familia' className='flex rounded-md w-full p-2 text-sm'>Terapia de Família</Link>}
                </Menu.Item>
            </Menu.Items>
        </Menu>
    )
}

const NavBar = () => {
    return <nav className='bg-white px-20 py-7 border-stone-300 border-b-4'>
        <ul className='sm:flex sm:items-center sm:justify-between'>
            <li>
                <Link to='/'>Página Inicial</Link>
            </li>
            <li>
                <Link to='/quem-sou'>Quem Sou</Link>
            </li>
            <li>
                <Link to='/experiencia'>Experiência</Link>
            </li>
            <li>
                <ServicesDropdown />
            </li>
        </ul>
    </nav>
}

export default NavBar
