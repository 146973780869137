import { Link } from 'react-router-dom'

const Home = () => {
    return <main>
        <div className='text-center my-28'>
            <p className='text-7xl font-bold my-10'>Claudia Silveira Furquim</p>
            <div className='py-10 bg-slate-100'>
                <p className='text-5xl'>Psicóloga</p>
                <p className='pt-3'>CRP 06/99155</p>
            </div>
        </div>
        <div className='md:grid md:grid-cols-3 text-center md:h-80'>
            <div className='bg-stone-300 grid grid-cols-1 place-content-between'>
                <p className='pt-4 p-2 text-4xl font-bold'>1.</p>
                <p className='p-2 text-4xl font-bold'>Psicoterapia Individual</p>
                <p className='p-8 text-sm'>Atendimento de adolescentes e adultos</p>
                <p className='pb-4 underline'><Link to='/psicoterapia-individual'>Leia Mais</Link></p>
            </div>
            <div className='bg-red-100 grid grid-cols-1 place-content-between'>
                <p className='pt-4 p-2 text-4xl font-bold'>2.</p>
                <p className='p-2 text-4xl font-bold'>Terapia de Casal</p>
                <p className='p-8 text-sm'>Atendimento de casais em conflito e crises</p>
                <p className='pb-4 underline'><Link to='/terapia-de-casal'>Leia Mais</Link></p>
            </div>
            <div className='bg-orange-100 grid grid-cols-1 place-content-between'>
                <p className='pt-4 p-2 text-4xl font-bold'>3.</p>
                <p className='p-2 text-4xl font-bold'>Terapia de Família</p>
                <p className='p-8 text-sm'>Atendimento de famílias com um membro adoecido e dificuldades de diálogo</p>
                <p className='pb-4 underline'><Link to='/terapia-de-familia'>Leia Mais</Link></p>
            </div>
        </div>
        <div className='md:grid md:grid-cols-4 text-center bg-stone-200 my-10'>
            <div className='p-6 col-span-2 bg-slate-50'>
                <p className='py-6 text-4xl font-bold'>Quem Sou</p>
                <p className='py-4 text-sm'>Nasci em 1986 em São Paulo (capital) e já residi em diversas cidades. Atualmente, moro em São Roque, interior de SP, com meu esposo. Me graduei em Psicologia na Universidade Federal de São Carlos em 2009 e desde a formação me encanto pelos temas social e família. Como psicóloga, atuo em consultório particular e também em um serviço público de assistência social de um município próximo onde resido.</p>
                <p className='py-4 underline'><Link to='/quem-sou'>Leia Mais</Link></p>
            </div>
            <div>
                <img className='object-cover h-full md:w-full mx-auto' alt='Claudia Silveira Furquim' src='/home.webp' />
            </div>
            <div className='p-6'>
                <p className='py-6 text-4xl font-bold'>Experiência</p>
                <p className='py-4 text-sm'>Atuo há 10 anos no atendimento individual e com famílias. Nos serviços de assistência social adquiri uma grande bagagem ao me dedicar ao atendimento de crianças e adolescentes vítimas de violência (física, sexual, psicológica), mulheres vítimas de violência doméstica e famílias com grandes conflitos e risco de rompimento de vínculos.</p>
                <p className='py-4 underline'><Link to='/experiencia'>Leia Mais</Link></p>
            </div>
        </div>
        <div className='grid grid-cols-5 text-center bg-red-100 py-16 my-10'>
            <p className='font-serif text-9xl'>&#8220;</p>
            <div className='col-span-3'>
                <p className='text-3xl py-2 font-handwritten'>Cada um de nós guarda uma porta da mudança que só pode ser aberta por dentro</p>
                <p className='text-sm pt-4'>Virgínia Satir</p>
            </div>
            <p className='font-serif text-9xl'>&#8221;</p>
        </div>
        <p className='text-3xl text-center px-4'>Para agendar seu atendimento entre em contato!</p>
    </main>
}

export default Home
