export const Experience = () => {
    return <main>
        <div className='p-10 bg-zinc-100'>
            <h1 className='py-7 font-bold text-center text-4xl'>Experiência</h1>
            <div className='p-5 overflow-auto'>
                <img alt='Claudia Silveira Furquim' className='hidden md:block md:float-right md:pl-7 mx-auto' src='/experience.webp'></img>
                <p className='my-5 text-justify'>Me graduei em Psicologia pela Universidade Federal de São Carlos em 2009.</p>
                <p className='my-5 text-justify'>No ano seguinte, ingressei no Aprimoramento Profissional em Psico-hematologia Pediátrica no Centro Infantil Boldrini, em Campinas. No hospital realizamos acompanhamento de crianças, e adolescentes em tratamento para doenças hematológicas, assim como de suas famílias. Foi uma experiência intensa e desafiadora, e aprendi muito sobre o impacto na família do adoecer de um membro, assim como sobre o luto.</p>
                <p className='my-5 text-justify'>Em 2011 ingressei para o serviço público municipal na área de Assistência Social. São mais de 10 anos atuando com indivíduos e famílias com vínculos fragilizados ou rompidos. Realizo atendimentos de crianças vítimas de maus tratos, negligência e violência, adolescentes vítimas de violência sexual e mulheres vítimas de violência doméstica. Também atendo famílias em conflito e com risco de rompimento de seus vínculos.</p>
                <p className='my-5 text-justify'>Na clínica me dedico ao atendimento individual e de famílias. Para oferecer um serviço de qualidade, invisto na formação continuada. Em 2014 participei do curso de Aperfeiçoamento ao Atendimento de Famílias, na Universidade de São Paulo. Atualmente, faço pós graduação em Terapia de Família e Casal pelo Instituto Sistemas Humanos, em Sorocaba.</p>
                <img alt='Claudia Silveira Furquim' className='md:hidden mx-auto' src='/experience.webp'></img>
            </div>
        </div>
    </main>
}

export default Experience
