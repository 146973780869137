const TopicTwo = () => {
    return <main>
        <div className='p-10 bg-red-100'>
            <h1 className='py-7 font-bold text-center text-4xl'>Terapia de Casal</h1>
            <div className='p-5 overflow-auto'>
                <p className='my-5 text-justify'>O casamento une dois mundos, duas histórias, duas vivências. Essa união pode passar por momentos turbulentos, seja por dificuldades de comunicação entre os pares, por divergências em relação aos desejos e expectativas, pelo adoecimento de um dos membros (psicológico ou físico), entre outros motivos.</p>
                <p className='my-5 text-justify'>A terapia de casal surge como um espaço de diálogo entre esses dois mundos, onde o terapeuta vai explorando a história e narrativas de cada um e possibilitando que ambos tomem consciência de seus funcionamentos, seus jeitos de amar, suas formas de se relacionarem, e como isso aparece a acontece na relação a dois. Ter uma profissional para mediar e co-construir essa troca, possibilita crescimento e transformação, tornando a relação mais equilibrada e saudável.</p>
            </div >
        </div >
    </main >
}

export default TopicTwo
