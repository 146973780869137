const About = () => {
    return <main>
        <div className='p-10 bg-stone-50'>
            <h1 className='py-7 font-bold text-center text-4xl'>Quem Sou</h1>
            <div className='p-5 overflow-auto'>
                <p className='my-5 text-justify'>Nasci em São Paulo (capital), assim como meu irmão mais velho, no ano de 1986. Morei por alguns anos em Londrina, no Paraná, onde nasceu minha irmã caçula. Com cerca de 13 anos, por motivo de trabalho, meus pais se mudaram para São Roque, interior de São Paulo, onde resido até hoje.</p>
                <img alt='Claudia Silveira Furquim' className='hidden md:block md:float-right md:pl-7 mx-auto' src='/who-am-i.webp'></img>
                <p className='my-5 text-justify'>A Psicologia entrou em minha vida de um jeito inusitado, através da sugestão de um amigo do ensino médio que fazia psicoterapia e afirmou que deveria ser psicóloga, pois eu sabia ouvir as pessoas.</p>
                <p className='my-5 text-justify'>Em 2005 ingressei no curso de Psicologia da Universidade Federal de São Carlos e me identifiquei muito com a psicologia social, área que me mantenho atuando até o dia de hoje. Foi na graduação que se iniciou também um interesse que só tomei consciência alguns anos depois: pelo tema Família.</p>
                <p className='my-5 text-justify'>Me graduei em 2009 e logo em seguida fiz uma aprimoramento profissional em hospital infantil, onde aprendi muito sobre o adoecer e o luto. Após a conclusão do aprimoramento, fui aprovada em um concurso público e iniciei minha atuação profissional na Assistência Social de um município vizinho ao meu. São mais de dez anos nessa área, aprendendo e se desafiando a cada dia. Atualmente, conciliou o consultório particular e o serviço público, e tenho muito orgulho da trajetória que sigo construindo.</p>
                <p className='my-5 text-justify'>No ano de 2012 me casei e conquistei um parceiro para descobrir cidadezinhas no interior de Minas Gerais e testar receitas novas na cozinha. Meu amor pelas famílias também passa pela minha e, apesar das diferenças, gosto muito de estar com meus familiares, conversar e gastar energia brincando com minhas sobrinhas.</p>
                <p className='my-5 text-justify'>Hoje, ser psicóloga e atuar no consultório, seja no individual, com famílias ou casais, me traz um conforto, um sentimento de que eu estou exatamente onde deveria estar. Isso tem muito a ver com o que me motiva, com uma grande paixão: ouvir histórias, conhecer e reconhecer as incontáveis formas de se relacionar.</p>
                <img alt='Claudia Silveira Furquim' className='md:hidden mx-auto' src='/who-am-i.webp'></img>
            </div>
        </div>
    </main>
}

export default About
