const TopicThree = () => {
    return <main>
        <div className='p-10 bg-orange-100'>
            <h1 className='py-7 font-bold text-center text-4xl'>Terapia de Família</h1>
            <div className='p-5 overflow-auto'>
                <p className='my-5 text-justify'>A terapia de família é realizada em grupo de modo a construir e desenvolver o diálogo entre os membros familiares e melhorar a comunicação, auxiliando na resolução de problemas e na conquista de relações respeitosas, harmônicas e saudáveis.</p>
                <p className='my-5 text-justify'>Muitas famílias buscam a terapia quando um de seus membros adoecem psicologicamente ou apresentam um comportamento inadequado e não aceito no grupo familiar. Essa mudança de um dos membros impacta sobre o funcionamento familiar e sinaliza que a comunicação naquela família esta deficiente.</p>
                <p className='my-5 text-justify'>O terapeuta familiar busca promover a conversa, abrindo o espaço de diálogo para todos os membros e tirando o foco do paciente identificado, pois todos possuem dores, anseios, expectativas e desejos que podem ser ditos, compartilhados e compreendidos.</p>
                <p className='my-5 text-justify'>As sessões são semanais com duração de 60 a 90 minutos.</p>
            </div >
        </div >
    </main >
}

export default TopicThree
