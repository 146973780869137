import Footer from './Footer'
import NavBar from './NavBar'

import './App.css'
import WhoAmI from './About'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './Home'
import Experience from './Experience'
import { ScrollToTop } from './ScrollToTop'
import TopicOne from './TopicOne'
import TopicTwo from './TopicTwo'
import TopicThree from './TopicThree'

import '@fontsource/dancing-script'
import '@fontsource/open-sans/300.css'

const App = () => {
  return <div>
    <Router>
      <ScrollToTop>
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/quem-sou' element={<WhoAmI />} />
          <Route path='/experiencia' element={<Experience />} />
          <Route path='/psicoterapia-individual' element={<TopicOne />} />
          <Route path='/terapia-de-casal' element={<TopicTwo />} />
          <Route path='/terapia-de-familia' element={<TopicThree />} />
        </Routes>
        <Footer />
      </ScrollToTop>
    </Router>
  </div>
}

export default App
