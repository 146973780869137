import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
    return <footer>
        <div className='p-10 sm:grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-3 text-sm'>
            <div className='py-3 col-span-1'>
            </div>
            <div className='py-3 col-span-1'>
                <ul>
                    <li className='font-bold'>Endereço</li>
                    <li>Av. Bandeirantes, 421</li>
                    <li>Jardim Bandeirantes</li>
                    <li>São Roque, SP</li>
                    <li>&nbsp;</li>
                    <li><a href='mailto:claudiafsfurquim@gmail.com'><FontAwesomeIcon icon={faEnvelope} /> claudiafsfurquim@gmail.com</a></li>
                    <li><a href='tel:+5511911567788'><FontAwesomeIcon icon={faPhoneAlt} /> (11) 91156-7788</a></li>
                    <li><a href='https://wa.me/5511911567788'><FontAwesomeIcon icon={faWhatsapp} /> (11) 91156-7788</a></li>
                    <li><a href='https://www.instagram.com/psi.claudiasilveirafurquim/'><FontAwesomeIcon icon={faInstagram} /> @psi.claudiasilveirafurquim</a></li>
                </ul>
            </div>
            <div className='py-3 col-span-1'>
                <ul>
                    <li className='font-bold'>Atendimento Online</li>
                    <li>Terça: 18h - 21h</li>
                    <li>Quinta: 17h - 21h</li>
                    <li>Sexta: 17h - 21h</li>
                    <li>&nbsp;</li>
                    <li className='font-bold'>Atendimento Presencial</li>
                    <li>Quarta: 17h - 21h</li>
                </ul>
            </div>
        </div>
        <div className='bg-stone-50 p-4'>
            <p className='text-center'>&#169; 2022 por Claudia Silveira Furquim. Hospedado por <a href='https://codexscribo.com'>CodexScribo</a>.</p>
            {process.env.NODE_ENV === 'development' && <p className='text-center text-xs'>{JSON.stringify(process.env)}</p>}
        </div>
    </footer>
}

export default Footer
