const TopicOne = () => {
    return <main>
        <div className='p-10 bg-stone-300'>
            <h1 className='py-7 font-bold text-center text-4xl'>Psicoterapia Individual</h1>
            <div className='p-5 overflow-auto'>
                <p className='my-5 text-justify'>O atendimento individual é um espaço exclusivo para o paciente, onde ele pode falar sobre suas dores, suas angustias e seus anseios.</p>
                <p className='my-5 text-justify'>Para que o individuo possa tomar consciência do seu jeito de ser (comportamentos e sentimentos) e das suas dificuldades, ofereço acolhimento e uma escuta atenta e dedicada.</p>
                <p className='my-5 text-justify'>Meu principal objetivo nos atendimentos individuais e auxiliar o paciente no seu processo de mudança, através do desenvolvimento de autonomia, consciência e responsabilidade sobre si.</p>
                <p className='my-5 text-justify'>Os atendimentos são agendados, tem duração de cerca de 50 minutos e possuem frequência semanal.A constância é algo fundamental para um bom processo terapêutico.</p>
                <p className='my-5 text-justify'>As sessões podem ser online ou presenciais.</p>
            </div >
        </div >
    </main >
}

export default TopicOne
